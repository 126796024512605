<template>
      <div>
         <article class="page type-page status-publish">
            <div class="inside-article">
               <div class="entry-content">
                  <div class="gb-container gb-container-4c6dd4be">
                     <div class="gb-inside-container">
                        <div class="gb-grid-wrapper gb-grid-wrapper-73c7cba1">
                           <div class="gb-grid-column gb-grid-column-e022ccd3">
                              <div class="gb-container gb-container-e022ccd3">
                                   <div class="gb-inside-container">
                                        <h2 class="gb-headline gb-headline-52c18e1d gb-headline-text">Come raggiungerci</h2>
                                        <p>
                                            Lo studio notarile Torrelli si trova nel centro storico dell'Aquila ed è raggiungibile:
                                        <p>
                                        <p>
                                            <b>da Pescara</b> con l'autostrada A25 fino all'uscita Bussi - Popoli continuando per la strada extraurbana S.S 17 Bussi - L'Aquila.
                                        </p>
                                        <p>
                                            <b>da Roma</b> è raggiungibile tramite l'autostrada A24 Roma - L'Aquila Ovest.
                                        </p>
                                        <p>
                                            <b>dall'autostrada Adriatica A14</b> invece uscire al casello Teramo - Giulianova  e prendere l'autostrada A24 per L'Aquila.
                                        </p>
                                        <p>
                                            Se hai bisogno di informazioni o per prenotare un appunatmento scrivici compilando la form qui di seguito.
                                        </p>
                                        <div class="gb-container gb-container-b4be0ee6">
                                           <div class="gb-inside-container">
                                              <div class="gb-grid-wrapper gb-grid-wrapper-b0cf6227">
                                                 <div class="gb-grid-column">
                                                       <h5 class="bold">Studio Notarile TORRELLI</h5>
                                                       Corso Vittorio Emanuele, 23 67100 L'Aquila (AQ)<br>
                                                       Tel. 0862 17 200 24<br>Cell. 351 62 18 378
                                                 </div>
                                              </div>
                                           </div>
                                        </div>
                                   </div>
                              </div>
                           </div>
                           <div class="gb-grid-column gb-grid-column-4a23959e">
                              <div class="gb-container gb-container-4a23959e">
                                 <div class="gb-inside-container">
                                    <div class="happyforms-form happyforms-styles happyforms-form--submit-button-border-hide margin-form">
                                       <FormContact/>
                                    </div>
                                 </div>
                              </div>
                           </div>
                        </div>
                        <div class="gb-grid-wrapper">
                           <div class="map">
                              <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d11794.70459508456!2d13.3994552!3d42.3494275!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x9164fe452abb6991!2sNotaio%20Giulia%20Torrelli!5e0!3m2!1sit!2sit!4v1645132081546!5m2!1sit!2sit" width="100%" height="400" style="border:0;" allowfullscreen="" loading="lazy"></iframe>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </article>
      </div>
</template>

<script>
   import FormContact from '@/components/FormContact'

   export default {
      name: 'Contacts',
      components : {
         FormContact
      },
      methods: {
         
      },
      data() {
         return {}
      }
   }
</script>

<style scoped>
   .gb-container-4a23959e{
        margin-top: 0 !important;
   }

   .map{
      width: 100%;
      margin-top: 30px;
   }

   .bold{
      font-weight: 700;
   }
</style>